<template>
  <div class="heading">
    <div class="heading__nav">
      <span class="heading__intro">
        {{ $t("game.messages") }}
      </span>
      <div class="heading__content">
        <span class="heading__content--number">
          {{ unreadMessagesCount }}
        </span>
        <span>{{ $t("game.unread") }}</span>
      </div>
    </div>
    <h3 class="heading__title" />
  </div>
</template>
<script>
export default {
  name: 'Heading',
  props: {
    unreadMessagesCount: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style lang="scss" scoped>
.heading {
  position: relative;
  &__nav {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  &__intro {
    color: var(--primary-color);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.8rem;
  }
  &__content {
    color: var(--primary-color);
    display: flex;
    align-items: center;
    padding: 1rem 0;
    &--number {
      background-color: var(--lighter-primary-color);
      color: var(--primary-color);
      display: block;
      padding: 0.2rem 1rem;
      border-radius: 4px;
      margin-left: 1rem;
      margin-right: 0.5rem;
      font-weight: 500;
    }
  }
}
</style>
