<template>
  <div class="messages">
    <div class="container__main-photo">
      <BaseImage
        :src="stage.sideImageUrl ? stage.sideImageUrl : require(`@/assets/images/sideImages/default.jpg`)"
        :name="$t('game.name')"
        lazy-mode
      />
    </div>
    <ScrollButton>
      <div class="container">
        <div class="container__wrapper">
          <Heading :unread-messages-count="unreadMessagesCount" />
          <div
            ref="accordion"
            class="messages-tab"
          >
            <Accordion :active-index="1">
              <AccordionItem
                v-for="(message, index) in messagesInReversedOrder"
                :key="`message-${index}`"
              >
                <template #accordion-trigger>
                  <div class="accordion__icon">
                    <Icon
                      :name="envelopeIcon(message.isCompleted())"
                      class="accordion__envelope"
                      :color="readColor(message.isCompleted())"
                    />
                  </div>
                  <h3 :style="{ color: readColor(message.isCompleted()) }">
                    {{ message.title }}
                  </h3>
                </template>
                <template #accordion-content>
                  <span v-html="message.body" />
                </template>
              </AccordionItem>
            </Accordion>
          </div>
          <div class="container__main-button">
            <BaseButton
              :is-loading="isLoading"
              @click="nextStep"
            >
              {{ $t("game.continue") }}
            </BaseButton>
          </div>
        </div>
      </div>
    </ScrollButton>
    <Feedback
      v-if="feedback !== null && showFeedback"
      :feedback="feedback"
      :is-loading="isLoading"
      @continue="nextStep"
    />
    <Badge
      v-if="badge !== null && showBadge"
      :badge="badge"
      :is-loading="isLoading"
      @show-all-badges="completedShowBadges"
      @continue="completed"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import colors from '@/common/colors/colors';
import Accordion from '@/components/Accordion/Accordion';
import AccordionItem from '@/components/Accordion/AccordionItem';
import Icon from '@/components/Icon/Icon';
import BaseImage from '@/components/Image/BaseImage';
import ScrollButton from '@/components/Content/ScrollButton';
import Heading from '@/components/Message/Partials/Heading';
import BaseButton from '@/components/Form/BaseButton';
import GameMessage from '@/domain/entities/flow/GameMessage';
import Feedback from '@/components/Feedback/Feedback';
import Badge from '@/components/Badge/Badge';
import variables from '@/assets/styles/utilities/_colors.scss';

export default {
  name: 'GameMessage',
  components: {
    Accordion,
    AccordionItem,
    BaseImage,
    Icon,
    ScrollButton,
    Heading,
    BaseButton,
    Feedback,
    Badge,
  },
  props: {
    stage: {
      type: Object,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
      validator: (messages) => messages.every((message) => message instanceof GameMessage),
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      variables,
      showBadge: false,
      showFeedback: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['interfaceColor']),
    messagesInReversedOrder() {
      return [...this.messages].reverse();
    },
    badge() {
      return this.unreadMessage.badge;
    },
    feedback() {
      return this.unreadMessage.feedback;
    },
    unreadMessagesCount() {
      return this.unreadMessages.length;
    },
    unreadMessage() {
      return [...this.unreadMessages].shift();
    },
    unreadMessages() {
      return this.messages.filter((message) => !message.isCompleted());
    },
  },
  methods: {
    ...mapActions('flow', ['markStageBadgeAsCompleted']),
    ...colors,
    envelopeIcon(isRead) {
      return isRead ? 'envelope-close' : 'envelope-open';
    },
    readColor(isRead) {
      return isRead
        ? this.variables.black
        : this.convertColorToRgb(this.interfaceColor);
    },
    nextStep() {
      if (this.feedback !== null && this.showFeedback === false) {
        this.showFeedback = true;

        return;
      }

      if (this.badge !== null) {
        this.showFeedback = false;
        this.markStageBadgeAsCompleted(this.unreadMessage);
        this.showBadge = true;

        return;
      }

      this.completed();
    },
    completedShowBadges() {
      this.$emit('completed-show-badges');
    },
    completed() {
      this.$emit('completed');
    },
  },
};
</script>
<style lang="scss" scoped>
.messages {
  position: relative;
}
h3 {
  font-weight: 500;
  font-size: 1.6rem;
}
.accordion__icon {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 17px;
  margin: 0 10px;
  color: $black;
  @include media-min($md) {
    align-items: center;
    margin: 5px 20px;
    padding-top: 0px;
  }
  svg {
    width: 18px;
    height: 18px;
  }
}

.messages-tab {
  border-top: 3px solid var(--lighter-primary-color);
  margin-top: 1rem;
  &__accordion {
    &__button {
      width: 100%;
      background-color: $white;
      color: $gray500;
      border: none;
      outline: none;
      text-align: left;
      padding: 1em 1.5em;
      font-size: 1em;
      cursor: pointer;
      transition: background-color 0.2s linear;
      display: flex;
      &:hover {
        background-color: $gray400;
      }
      div {
        margin: auto 0 auto auto;
        .arrow {
          border: solid $black;
          border-width: 0 2px 2px 0;
          display: inline-block;
          padding: 4px;
          transform: rotate(-135deg);
          transition: transform 0.3s ease-out;
        }
      }
    }
    &__content {
      padding: 0 1em;
      height: 0;
      overflow: hidden;
      transition: height 0.2s ease-out;
    }
  }
}
</style>
